<template>
  <div class="videoRecommend">
    <div class="Recommend_text">为你推荐</div>

    <div class="video_list">
      <div
        class="video_item"
        @click="videoClikc(item)"
        v-for="item in recommendList"
        :key="item.id"
      >
        <div class="video_img">
          <img :src="url + item.imgUrl" alt="" />
          <img
            class="img"
            :src="require('assets/images/writing/shadow.png')"
            alt=""
          />

          <!-- <div class="duration">9:30</div> -->
        </div>
        <div class="video_right_box">
          <div class="video_title">{{ item.title }}</div>
          <div class="video_related">
            <div class="heat">
              <i class="heat_icon" />
              {{ item.hot }}
            </div>
            <div class="score">
              <i class="score_icon" />
              {{ item.star.toFixed(1) }}
            </div>
            <div class="review">
              <i class="score_icon" />
              {{ item.commentNum }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "videoRecommend",
  inject: ["reload"],
  props: {
    recommendList: {
      type: Array,
    },
  },
  data() {
    return {
      url: this.$store.state.onlineBasePath,
    };
  },
  methods: {
    // 视频 item 点击
    videoClikc(val) {
      this.$router.push({
        name: "videoWritingInfo",
        params: {
          id: val.id,
        },
      });
      this.reload();
    },
  },

  created() {},
};
</script>

<style lang="scss" scoped>
.videoRecommend {
  margin-top: 85px;
  .Recommend_text {
    font-size: 18px;

    font-weight: bold;
    padding: 5px 10px;
    border-left: 3px solid #ff6900;
  }

  .video_list {
    margin-top: 30px;
    .video_item {
      cursor: pointer;
      display: flex;
      margin-bottom: 20px;
      .video_img {
        width: 264px;
        height: 148px;
        position: relative;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
        }

        .img {
          height: auto;
          transform: translateY(-59px);
        }

        .duration {
          position: absolute;
          bottom: 10px;
          right: 10px;
          color: #fff;
          opacity: 0.8;
        }
      }

      .video_right_box {
        width: 155px;
        .video_title {
          font-size: 16px;
        }

        .video_related {
          margin-top: 10px;
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          color: #666666;
          .heat {
            display: flex;
            align-items: center;
            .heat_icon {
              width: 14px;
              height: 14px;
              margin-right: 10px;
              background: url("~assets/images/info/hot.png") no-repeat;
              background-size: 100% 100%;
            }
          }

          .score {
            display: flex;
            align-items: center;
            .score_icon {
              width: 14px;
              height: 14px;
              margin-right: 10px;
              background: url("~assets/images/writing/score_yellow.png")
                no-repeat;
              background-size: 100% 100%;
            }
          }

          .review {
            display: flex;
            align-items: center;
            .score_icon {
              width: 14px;
              height: 14px;
              margin-right: 10px;
              background: url("~assets/images/writing/review.png") no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
    }
  }
}
</style>
