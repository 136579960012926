<template>
  <div class="videoContent">
    <div class="title">{{ videoInfo.title }}</div>
    <div class="share_a" @click="share">
      <img :src="require('assets/images/share-blue.png')" alt="" />
      分享视频
    </div>

    <div class="video_box">
      <videoPlayerPage ref="videoPlayer" />
    </div>
    <!-- 链接二维码弹窗 -->
    <el-dialog
      title="二维码"
      :close-on-click-modal="false"
      :visible.sync="QrCodeVisible"
      @close="handleCloseQrcode"
      class="qrcode"
    >
      <div class="content">
        <div class="notes">分享二维码</div>
        <div class="imgBox">
          <div ref="qrcode_box"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QrCode from "qrcodejs2";
import videoPlayerPage from "components/videoInfoComponents/videoPlayerPage";
export default {
  components: {
    videoPlayerPage,
  },
  name: "videoContent",
  props: {
    videoInfo: {
      type: Object,
    },
  },
  watch: {
    videoInfo(val) {
      this.$refs["videoPlayer"].playerOptions.sources.push({
        type: "video/mp4",
        src: this.url + val.videoUrl,
      });
      this.$refs["videoPlayer"].playerOptions.poster = this.url + val.imgUrl;
    },
  },
  data() {
    return {
      url: this.$store.state.onlineBasePath,
      // 二维码弹框
      QrCodeVisible: false,
    };
  },
  methods: {
    // 关闭去除二维码
    handleCloseQrcode() {
      // console.log(1);
      this.$refs.qrcode_box.innerHTML = "";
    },
    // 分享视频
    share() {
      console.log(this.videoInfo);
      this.QrCodeVisible = true;
      this.$nextTick(() => {
        this.crateQrcode();
      });
    },
    // 生成二维码
    crateQrcode() {
      this.qr = new QrCode(this.$refs.qrcode_box, {
        text: `https://writemall.com/videoWritingInfo/${this.videoInfo.id}`,
        width: 128,
        height: 128,
        colorDark: "#000000",
        colorLight: "#ffffff",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.videoContent {
  .title {
    margin-top: 30px;
    font-size: 22px;
    font-weight: 800;
  }

  .share_a {
    cursor: pointer;
    margin-top: 15px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #ff6900;
    img {
      width: 15px;
      height: 15px;
      margin-right: 12px;
    }
  }

  .video_box {
    margin-top: 20px;
  }
  .qrcode {
    ::v-deep .el-dialog {
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0 !important;
      width: 500px;
      height: 390px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        line-height: 42px;
        .el-dialog__title {
          margin-left: 15px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: $font-size-small;
          right: $font-size-small;
          font-size: $font-size-large;
        }
      }
      .el-dialog__body {
        padding: 65px 20px;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .notes {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-black;
          }
          .imgBox {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
</style>
