<template>
  <div class="videoBottomTab">
    <!-- tab -->
    <el-tabs type="card">
      <!-- 简介 -->
      <el-tab-pane label="简介">
        <div class="abstract">
          <div class="title">{{ videoInfo.title }}</div>

          <div class="related">
            <div class="watch">{{ videoInfo.watchNumber }}万人观看</div>
            <div class="heat">
              <i class="heat_icon" />
              {{ videoInfo.hot }}
            </div>
            <div class="score">
              <i class="score_icon" />
              {{ videoInfo.star.toFixed(1) }}
            </div>
          </div>

          <p v-html="videoInfo.stepsContent"></p>
        </div>
      </el-tab-pane>
      <!-- 评论 -->
      <el-tab-pane :label="'评论(' + commentList.length + ')'">
        <div class="comments_list">
          <div class="fabu" @click="commentsDialog = true">评论</div>
          <div class="kong" v-if="commentList.length == 0">
            <img :src="require('assets/images/kong.png')" alt="" />
            暂无评论
          </div>
          <div
            class="item"
            v-else
            v-for="(item, index) in commentList"
            :key="index"
          >
            <div class="top">
              <div class="item_avatar">
                <img
                  :src="
                    item.headImg
                      ? $store.state.onlineBasePath + item.headImg
                      : require('assets/images/user.png')
                  "
                  @error="errorImg(item)"
                />
              </div>

              <div class="rate_box">
                <span class="name"> {{ item.userName }} </span>
                <el-rate v-model="item.star" disabled text-color="#ff9900">
                </el-rate>
              </div>
            </div>

            <div class="item_content">
              <div class="item_text">
                {{ item.content }}
              </div>

              <div class="item_imglist">
                <img
                  v-for="(v, i) in item.fileList"
                  :key="i"
                  :src="$store.state.onlineBasePath + v"
                  alt=""
                  @click="magnifyImg(v)"
                />
              </div>

              <div class="item_like">
                <div class="like" @click="like(item)">
                  <img :src="require('assets/images/fabu.png')" alt="" />
                  赞<span>({{ item.praiseNum }})</span>
                </div>

                <div class="date">{{ item.createTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 评论 -->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :visible.sync="commentsDialog"
      title="评论该篇模板"
      @close="closeCommentsDialog"
      class="commentsDialog"
    >
      <div class="content">
        <div class="rate_box">
          <el-rate v-model="rate"></el-rate>
          评分 {{ rate }}
        </div>

        <div class="textarea">
          <el-input
            type="textarea"
            :rows="7"
            placeholder="非常感谢您的真知灼见，我们将迅速落实并反馈。"
            v-model="textarea"
          >
          </el-input>
        </div>

        <div class="up_box">
          <templatetUpload ref="templatetUpload" />
        </div>
      </div>
      <div class="footer">
        <div class="footer-btn clear" @click="commentsDialog = false">取消</div>
        <div class="placeholder"></div>
        <div class="footer-btn" @click="commentsSubmit">提交</div>
      </div>
    </el-dialog>

    <!-- 图片放大弹窗 -->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :visible.sync="imgDialog"
      class="imgDialog"
    >
      <img
        width="100%"
        height="100%"
        :src="$store.state.onlineBasePath + imgUrl"
        alt=""
      />
    </el-dialog>
  </div>
</template>

<script>
import templatetUpload from "components/idiom/templatetUpload";
import { getContentById, praise, helpManualCommentAdd } from "api/videoWriting";
export default {
  name: "videoBottomTab",
  components: {
    templatetUpload,
  },
  props: {
    videoInfo: {
      type: Object,
    },
  },
  data() {
    return {
      // 图片放大弹窗
      imgDialog: false,
      // 图片放大url
      imgUrl: "",
      // 评论弹窗
      commentsDialog: false,
      // 评论数组
      commentList: [],
      // 评论内容
      textarea: "",
      // 评分
      rate: 5,
      // 评论总数
      total: 0,
    };
  },
  methods: {
    // 提交评论
    async commentsSubmit() {
      const data = {
        helpManualId: this.$route.params.id,
        content: this.textarea,
        star: this.rate,
        files: this.$refs["templatetUpload"].arr.toString(),
      };

      const res = await helpManualCommentAdd(data);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "发布成功",
          duration: "1000",
        });

        this.getCommentList(this.$route.params.id);
        this.commentsDialog = false;
      } else {
        this.$message({
          type: "error",
          message: res.message,
          duration: "1000",
        });
      }
    },
    // 评论弹窗关闭
    closeCommentsDialog() {
      this.textarea = "";
      this.rate = 5;
      this.$refs["templatetUpload"].img = [];
      this.$refs["templatetUpload"].arr = [];
    },

    // 点赞
    async like(item) {
      const data = {
        helpManualCommentId: item.id,
      };

      const res = await praise(data);
      item.praiseNum = res.data;
    },

    // 图片容错
    errorImg(val) {
      val.headImg = require("assets/images/user.png");
      //数据更新强制渲染页面
      this.$forceUpdate();
    },

    // 图片放大
    magnifyImg(val) {
      this.imgUrl = val;
      this.imgDialog = true;
    },

    // 获取评论列表
    async getCommentList(helpManualId) {
      const data = {
        helpManualId,
      };

      const res = await getContentById(data);
      this.commentList = res.data.data;
      this.total = res.data.total;
    },
  },
  created() {
    this.getCommentList(this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped>
.videoBottomTab {
  margin-top: 25px;

  ::v-deep .el-tabs__item {
    background-color: #f7f7f7;
  }
  ::v-deep .is-active {
    background-color: #fff;
  }

  // 简介
  .abstract {
    .title {
      margin-top: 30px;
      font-size: 18px;
      font-weight: bold;
    }

    .related {
      margin-top: 15px;
      font-size: 14px;
      color: #666666;
      display: flex;
      div {
        margin-right: 20px;
      }

      .heat {
        display: flex;
        align-items: center;
        .heat_icon {
          width: 14px;
          height: 14px;
          margin-right: 10px;
          background: url("~assets/images/info/hot.png") no-repeat;
          background-size: 100% 100%;
        }
      }

      .score {
        display: flex;
        align-items: center;
        .score_icon {
          width: 14px;
          height: 14px;
          margin-right: 10px;
          background: url("~assets/images/writing/score_yellow.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    p {
      line-height: 1.8;
      margin-top: 30px;
      text-align: justify;
      text-justify: distribute-all-lines;
    }
  }

  // 评论
  .comments_list {
    .kong {
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #888888;
      img {
        width: 170px;
        height: 150px;
        margin-bottom: 20px;
      }
    }
    .item {
      margin-top: 20px;

      .top {
        display: flex;
        .item_avatar {
          margin-right: 30px;
          display: flex;
          align-items: center;
          flex-direction: column;

          img {
            border-radius: 50%;
            width: 50px;
            height: 50px;
          }
        }

        .rate_box {
          font-size: 18px;
          font-weight: 500;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          ::v-deep .el-rate {
            .el-rate__item {
              .el-rate__icon {
                font-size: 20px;
              }
            }
          }
        }
      }

      .item_content {
        flex: 1;

        .item_text {
          margin-top: 20px;
          font-size: 14px;
          line-height: 1.8;
        }

        .item_imglist {
          margin: 25px 0;
          img {
            border: 1px solid #e9e9e9;
            width: 80px;
            height: 80px;
            margin-right: 20px;
          }
        }

        .item_like {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;

          .like {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #b7b7b7;
            img {
              width: 16px;
              height: 16px;
              margin-right: 5px;
            }
            span {
              margin-left: 5px;
            }
          }

          .date {
            margin-right: 20px;
            font-size: 14px;
            color: #b7b7b7;
          }
        }
      }
    }
  }

  // 评论弹窗
  .commentsDialog {
    ::v-deep .el-dialog {
      @include flex-center(column);
      justify-content: normal;
      align-items: normal;
      width: 360px;

      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 20px 20px 0px;
        .el-dialog__title {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 12px;
        }
      }
      .el-dialog__body {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 20px;
        .content {
          .rate_box {
            display: flex;
            align-items: center;

            .el-rate {
              margin-right: 10px;
            }
          }
          .textarea {
            margin-top: 20px;
            .el-textarea {
              .el-textarea__inner {
                resize: none;
              }
            }
          }
        }
        .footer {
          display: flex;
          justify-content: space-between;
          margin-top: 28px;
          padding: 0 40px;
          .footer-btn {
            @include noselect;
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-bg-heightlight;
            cursor: pointer;
            &.clear {
              color: #999999;
            }
          }

          .placeholder {
            width: 1px;
            height: 20px;
            background: #e8e8e8;
          }
        }
      }
    }
  }

  // 图片放大弹窗
  .imgDialog {
    img {
      image-rendering: -webkit-optimize-contrast;
    }
  }
}
</style>
