<template>
  <div>
    <div class="up_box">
      <div class="ul">
        <div class="bg" v-for="(item, index) in img" :key="index">
          <img :src="item" alt="" />
        </div>

        <div class="sc" @click="sv" v-if="img.length < 8">
          <i class="el-icon-plus"></i>
        </div>
      </div>
      <input
        id="upload"
        type="file"
        accept=".gif,.jpg,.jpeg,.png,.gif,.jpg,.png"
        ref="file"
        multiple="multiple"
        @change="doRealUpload"
      />
    </div>
  </div>
</template>

<script>
import { upload } from "api/common";
export default {
  provide() {
    return {
      disk: this.img,
    };
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],
      img: [],
      arr: [],
      bearUrl: "https://fafs.antiplagiarize.com/",
    };
  },
  methods: {
    async doRealUpload(e) {
      let file = e.target.files;

      for (const key in file) {
        if (file.hasOwnProperty.call(file, key)) {
          let form = new FormData();
          form.append("file", file[key]);
          const res = await upload(form);
          if (res.code === 200) {
            this.img.push(URL.createObjectURL(file[key]));
            this.arr.push(res.data);
          }
        }
      }
    },
    sv() {
      this.$refs.file.click();
    },
  },
};
</script>

<style lang="scss" scoped>
#upload {
  display: none;
}
.up_box {
  display: flex;
  .ul {
    display: flex;
    flex-wrap: wrap;
  }
  .bg {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    margin-top: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .sc {
    margin-top: 20px;
    cursor: pointer;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;

    border: 1px solid #e7e9ed;

    .el-icon-plus {
      color: #e7e9ed;
    }
  }
}
</style>
