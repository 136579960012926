<template>
  <div class="videoInfo">
    <!-- 顶部nav -->
    <div class="topNav">
      <el-breadcrumb separator="＞">
        <el-breadcrumb-item :to="{ path: '/videoWriting' }">
          首页</el-breadcrumb-item
        >
        <!-- <el-breadcrumb-item :to="{ path: '/videoWriting' }"
          >金句小课堂</el-breadcrumb-item
        > -->
        <el-breadcrumb-item class="is_link">{{
          videoInfo.title
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!-- 内容 -->
    <div class="content">
      <div class="left_box">
        <!-- 视频 -->
        <videoContent :videoInfo="videoInfo" />

        <!-- 底部tab -->
        <videoBottomTab :videoInfo="videoInfo" />
      </div>
      <div class="right_box">
        <!-- 推荐 -->
        <videoRecommend :recommendList="recommendList" />
      </div>
    </div>
  </div>
</template>

<script>
import { getClassContentById, getListById } from "api/videoWriting";
import videoContent from "components/videoInfoComponents/videoContent";
import videoRecommend from "components/videoInfoComponents/videoRecommend";
import videoBottomTab from "components/videoInfoComponents/videoBottomTab";
export default {
  name: "videoInfo",
  components: {
    videoContent,
    videoRecommend,
    videoBottomTab,
  },
  data() {
    return {
      // 视频
      videoInfo: {},
      // 推荐
      recommendList: [],
      //
    };
  },
  methods: {
    // 获取视频详情
    async getVideoInfo(id) {
      const params = {
        id,
      };

      const res = await getClassContentById(params);
      this.videoInfo = res.data;
      this.getRecommendList(res.data.parentid);
    },

    // 获取推荐列表
    async getRecommendList(parentId) {
      const params = {
        parentId,
      };
      const res = await getListById(params);

      this.recommendList = res.data.data;
    },
  },

  created() {
    this.getVideoInfo(this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped>
.videoInfo {
  background: #ffffff;
  padding: 30px 40px;

  //  顶部nav
  .topNav {
    padding: 5px 10px;
    border-left: 3px solid #ff6900;
    .is_link {
      ::v-deep .el-breadcrumb__inner {
        font-weight: 700;
        text-decoration: none;
        transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        color: #303133;
      }
    }
  }

  // 内容
  .content {
    display: flex;
    .left_box {
      flex: 1;
    }

    .right_box {
      min-width: 430px;
      margin-left: 30px;
    }
  }
}
</style>
